<template>
  <div style="padding: 20px">
    <div style="display:flex;">
      <select-area @areaListId="getAreaListId"/>
      <div style="margin-bottom: 20px">
        <el-input v-model="query.coachName" placeholder="教练姓名" style="width: 160px;"/>
        <el-select style="width: 140px;margin: 0 10px"
            v-model="query.coachType"
            placeholder="教练类型"
            clearable
        >
          <el-option key="1" label="全职" :value="1"></el-option>
          <el-option key="0" label="兼职" :value="0"></el-option>
        </el-select>
        <el-select style="width: 140px;margin: 0 10px"
                   v-model="query.isVerify"
                   placeholder="是否确认"
                   clearable
        >
          <el-option key="1" label="是" :value="1"></el-option>
          <el-option key="0" label="否" :value="0"></el-option>
        </el-select>
        <el-date-picker
            v-model="yearAndMonth"
            type="month"
            style="width: 170px;"
            value-format="YYYY-MM"
            placeholder="日期选择">
        </el-date-picker>
        <el-button
            type="primary"
            style="margin-left: 10px"
            @click="getData"
        >搜索
        </el-button>
        <el-popconfirm @confirm="exportCourses" title="确定要导出吗？">
          <template #reference>
            <el-button type="primary">导出</el-button>
          </template>
        </el-popconfirm>
      </div>
    </div>
    <el-table
        :data="tableData"
        border
        v-loading="tableLoading"
        height="606px"
        ref="multipleTable"
        :row-style="{ height: '15px' }"
        :cell-style="{ padding: '14px' }"
        header-cell-class-name="table-header"
    >
      <el-table-column prop="coachId" label="教练Id" width="90"/>
      <el-table-column prop="coachName" label="教练姓名" width="110"/>
      <el-table-column prop="coachType" label="教练类型" width="110"/>
      <el-table-column prop="courseTime" label="日期" width="110"/>
      <el-table-column label="确认时间" width="190">
        <template #default="scope">
          <div>{{scope.row.isVerify == 1 ? scope.row.verifyTime : "暂未确认"}}</div>
        </template>
      </el-table-column>
      <el-table-column label="上课校区" show-overflow-tooltip="true">
        <template #default="scope">
            {{ scope.row.areaId }} - {{ scope.row.areaName }}
        </template>
      </el-table-column>
      <el-table-column label="城市" show-overflow-tooltip="true">
        <template #default="scope">
          {{ scope.row.cityName }}
        </template>
      </el-table-column>
      <el-table-column label="课程类型" width="700">
        <template #default="scope">
          <div style="display: flex;">
            <div style="margin-right: 10px" v-for="(item,index) in scope.row.mapCourse" :key="index">
              <el-tag>{{item.ctype}} - {{item.ctypeNum}}</el-tag>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination
          border
          layout="total, prev, pager, next"
          :current-page="query.page"
          :page-size="query.pageSize"
          :total="pageTotal"
          @current-change="handlePageChange"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import selectArea from "../../components/SelectArea";

import {GetCoursesRecordStatistics,exportCoursesRecordStatistics} from "@/api/coursesRecord";
export default {
  name: "CourseConfirm",
  components: {
    selectArea,
  },
  data() {
    return {
      yearAndMonth:null,
      tableLoading:false,
      tableData:[],
      pageTotal:0,
      query:{
        areaList: [],
        coachId: null,
        coachName: "",
        month: null,
        page: 1,
        pageSize: 10,
        isVerify:null,
        coachType:null,
        year: null
      }
    }
  },
  methods:{
    getAreaListId(val) {
      this.query.areaList = val;
    },
    handlePageChange(val) {
      this.query.page = val;
      this.getData();
    }, // 重置
    getData(){
      this.tableLoading = true;
      GetCoursesRecordStatistics(this.query).then(val =>{
        this.tableLoading = false;
        if (val.code == 200) {
          this.tableData = val.data.records;
          this.pageTotal = val.data.total;
        } else {
          this.$message.error("获取表格数据失败");
        }
      })
    },
    exportCourses(){
      exportCoursesRecordStatistics(this.query);
    },
  },
  created(){
    this.getData();
  },
  watch:{
    yearAndMonth(newData) {
      if (newData){
        let date = this.yearAndMonth.split('-');
        this.query.year = date[0];
        this.query.month = date[1];
      }else {
        this.query.year = null;
        this.query.month = null;
      }
    }
  }
}
</script>

<style scoped>

</style>

