import request from "../utils/request";

export const page = (query) => {
    return request({
        url: '/sys/courses/page',
        method: 'post',
        data: query
    });
}

/**
 * 课时确认分页
 */
export const GetCoursesRecordStatistics = (query) => {
    return request({
        url: '/sys/courses/GetCoursesRecordStatistics',
        method: 'post',
        data: query
    });
}

export const deleteStudent = (id, coursesRecordId) => {
    return request({
        url: '/sys/courses/delete',
        method: 'get',
        params: {id, coursesRecordId}
    });
}

export const deleteRecord = (id) => {
    return request({
        url: '/sys/courses/deleteRecord',
        method: 'get',
        params: {id}
    });
}

export const classType = () => {
    return request({
        url: '/sys/courses/classType',
        method: 'get',
    });
}
/**
 * 导出
 * @param query
 * @returns {AxiosPromise}
 */
export const excel = (query) => {
    return request({
        url: '/sys/courses/excel',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    });
};
/**
 * 添加学生
 * @param student
 * @returns {AxiosPromise}
 */
export const coursesAddStudent = (student) => {
    return request({
        url: '/sys/courses/coursesAddStudent',
        dataType: "json",
        method: 'POST',
        data: student,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
/**
 *订单详情
 * @param orderId
 * @returns {AxiosPromise}
 */
export const selectOrderCourses = (orderId) => {
    return request({
        url: '/sys/orders/details/selectOrderCourses',
        method: 'get',
        params: {orderId}
    });
};
/**
 * 订单赠卡上课记录
 * @param orderId
 * @returns {AxiosPromise}
 */
export const selectSendOrderCourses = (orderId) => {
    return request({
        url: '/sys/orders/details/selectSendOrderCourses',
        method: 'get',
        params: {orderId}
    });
};

/**
 * 详情回访
 * @param orderId
 * @returns {AxiosPromise}
 */
export const selectOrderFollow = (orderId) => {
    return request({
        url: '/sys/orders/details/selectOrderFollow',
        method: 'get',
        params: {orderId}
    });
};
//web修改课时(上课老师、校区) 
export const upCourseTeacherAndCampus = (query) => {
    return request({
        url: '/sys/courses/upCourseTeacherAndCampus',
        dataType: "json",
        method: 'POST',
        data: query,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    });
};
//课时确认表导出
export const exportCoursesRecordStatistics = (query) => {
    return request({
        url: '/sys/courses/exportCoursesRecordStatistics',
        method: 'post',
        data: query,
        responseType: 'arraybuffer'
    });
};

